import React from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import Layout from '../../components/Layouts/layout'
import SEO from '../../components/seo'
import IFrameCont from '../../components/iFrame/iFrame'

const FileComplaintPage = () => {
  const intl = useIntl()

  return (
    <Layout
      subheader={<FormattedMessage id='complaintForms.subHdr' />}
      header={<FormattedMessage id='complaintForms.licensedHdr' />}
    >
      <SEO
        tlang={intl.locale}
        title={intl.formatMessage({ id: 'nav.fileComplaint' })}
      />
      <Container>
        <Row>
          <Col>
            <IFrameCont
              title='File A Builder/Vendor Complaint Form'
              src={
                intl.locale === 'en'
                  ? 'https://builderportal.hcraontario.ca/en-US/licenced-vb-complaint-form/'
                  : 'https://builderportal.hcraontario.ca/fr-FR/licenced-vb-complaint-form/'
              }
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default FileComplaintPage
